import {
  ElMessage,
  ElMessageBox,
  type ElMessageBoxOptions,
  type MessageOptions,
} from 'element-plus';

export const messageBoxAlert = (
  title: string,
  description: string,
  options: ElMessageBoxOptions = { confirmButtonText: 'Подтвердить' },
) =>
  ElMessageBox.alert(description, title, {
    dangerouslyUseHTMLString: true,
    customClass: 'message-confirm',
    ...options,
  });

export const successMessage = (message: string, options: MessageOptions = {}) =>
  ElMessage({
    type: 'success',
    message,
    ...options,
  });

export const errorMessage = (message: string, options: MessageOptions = {}) =>
  ElMessage({
    type: 'error',
    message,
    ...options,
  });
